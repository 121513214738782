html, body, #root {
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: skyblue;
  margin: 0;
  font-family: serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 { font-size: 2rem; }
img { object-fit: cover; max-width: 100%; }
.hidden { visibility: hidden; }

#sidebar-button {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  border: none;
  margin: 15px 10px;
  padding: 5px;
  background-color: whitesmoke;
  color: black;
  box-shadow: 0px 2px 2px 0px #575757;
  cursor: pointer;
  font-weight: bold;
}
#sidebar-button * { 
  margin: 2px; 
  pointer-events: auto;
}

.side-index {
  z-index: 4;
  padding: 40px 0;
  min-width: 150px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-index button {
  border: none;
  background-color: unset;
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
  pointer-events: auto;
}

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: skyblue;
  margin: 5px;
  padding: 1rem;
  border-radius: 1rem;
}
.auth-box input { margin-top: 1rem }

.book {
  width: calc(100% - 20px) !important; 
  height: calc(100% - 60px) !important; 
  margin: 30px 10px;
  max-width: 900px;
  max-height: 660px !important;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 4px #251f15, 0px 0px 5px 5px rgba(0, 0, 0, 0.2); 
}
.book-cover {
  position: absolute;
  color: whitesmoke;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(80% - 10px) !important; ;
  max-width: 450px !important; 
  height: calc(100% - 60px) !important; 
  max-height: 660px !important;
  border-radius: 2px;
  background-color: #251f15;
  box-shadow: 0px 0px 0px 4px #251f15, 0px 0px 5px 5px rgba(0, 0, 0, 0.2); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.book-article { 
  display: flex; 
  max-height: 100%; 
  /* background-color: whitesmoke; */
}
.page-half { 
  flex: 1; 
  display: flex; 
  flex-direction: column; 
  margin: 1rem;
  max-height: 100%;
  overflow-y: hidden;
}

.page-half img {
  max-height: 100%;
  object-fit: contain;
}

